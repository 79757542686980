import imageIcon from "../../images/icons/picture.svg";
import pdfIcon from "../../images/icons/pdf-file.svg";
import saveIcon from "../../images/icons/save.svg";
import { prettyFileSize } from "./FileUpload";
import { URL, URLProd } from "../paths";
import { useContext, useState } from "react";
import { ViewContext } from "../contexts/ViewContext";
import Preview from "./Preview";

export default function FileUploadListItem({
  module,
  subModule,
  file,
  setUpdates,
}: {
  module: string;
  subModule?: string;
  file: any;
  setUpdates?: Function;
}) {
  const fileNameSoleWithExt = file.name.replace(/.*_(.*)/, "$1");
  const fileNameSole = fileNameSoleWithExt.replace(/(\..*)(?!.*\.)/, "");

  const { modal2, setModal2 } = useContext(ViewContext);

  const [description, setDescription] = useState<string>(
    file.description ? file.description : fileNameSole
  );

  const [saved, setSaved] = useState(false);

  async function removeFile() {
    const response = await fetch(`${URL}backend/inserts/remove-file.php`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: file.id,
      }),
    });
    const result = await response.json();

    if (setUpdates) {
      await setUpdates((prev: number) => prev + 1);
    }
  }

  async function changeDescription() {
    const response = await fetch(
      `${URL}backend/inserts/set-update-description.php`,
      {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({
          id: file.id,
          description: description,
        }),
      }
    );
    const result = await response.json();
    if (response.ok) {
      setSaved(true);
      if (setUpdates) {
        setUpdates((prev: number) => prev + 1);
      }
    }
  }

  const link = file?.path
    ? `${URLProd}uploads/files/${file.path}`
    : `${URLProd}uploads/files/${module}${subModule ? "/" + subModule : ""}/${
        file.name
      }`;

  function openPreview() {
    setModal2({
      show: true,
      style: { minWidth: "60vw" },
      heading: "Podgląd",
      content: (
        <Preview
          format={
            file.format.match(/image/)
              ? "image"
              : file.format.match(/pdf/)
              ? "pdf"
              : file.format.match(/audio/)
              ? "audio"
              : file.format.match(/video/)
              ? "video"
              : ""
          }
          data={file}
          link={link}
        />
      ),
    });
  }
  return (
    <div className="file-upload-list-item">
      <div className="file">
        <div>
          {/* <a target="_blank" href={link}> */}
          {file.format.match(/image/) || file.format.match(/pdf/) ? (
            <img
              onClick={openPreview}
              src={file.format.match(/image/) ? imageIcon : pdfIcon}
            />
          ) : (
            <span onClick={openPreview} style={{ fontSize: "8px" }}>
              {file?.format?.split("/").at(-1)}
            </span>
          )}
          {/* </a> */}
          <div className="file-names">
            <div className="file-description">
              <input
                type="text"
                onChange={(e: any) => {
                  setDescription(e.target.value);
                  setSaved(false);
                }}
                value={description}
              />
              {saved ? <span>✓</span> : <></>}
              <img onClick={() => changeDescription()} src={saveIcon} />
              <div className="files-bottom-bar">
                <div className="options">
                  <div>
                    <a target="_blank" href={link}>
                      Otwórz
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      href={link}
                      download={fileNameSoleWithExt}
                    >
                      Zapisz
                    </a>
                  </div>
                </div>
                {/* <div className="file-name">{file.name}</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="file-size" style={{ color: "grey" }}>
          {prettyFileSize(file.size)}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (
              window.confirm("Czy na pewno chcesz przenieść plik do kosza?")
            ) {
              removeFile();
            }
          }}
        >
          ✖
        </div>
        {/* {JSON.stringify(file)} */}
      </div>
    </div>
  );
}
