import { createContext, useContext, useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";

import newIcon from "../../images/icons/new-file.svg";
import filterIcon from "../../images/icons/filter.svg";
import viewSwitchIcon from "../../images/icons/view-switch.svg";
import activeSwitchIcon from "../../images/icons/active-switch.svg";

import Form from "../forms_new/Form";

import Loading from "../common/Loading";

import Pagination from "./Pagination";
import Info from "../common/Info";
import ViewTable from "./ViewTable";
import ViewCards from "./ViewCards";
import Filters from "./Filters";
import ViewTimeline from "./ViewTimeline";
import { UserContext } from "../contexts/UserContext";
import { objectOrJSONparse } from "../functions/tools";
import { Route, Routes } from "react-router-dom";
import ViewError from "./Schemas/Errors/ViewError";
import DateSwitch from "./DateSwitch";

export const RegisterViewContext = createContext<any>({});

View.defaultProps = {
  schema: () => ({}),
};
export default function View({
  heading,
  source,
  params,
  rowsPerPage,
  query,
  rowSchema,
  schema,
  dated,
}: {
  heading?: string | JSX.Element;
  source: string;
  params?: any;
  rowsPerPage?: number;
  query?: any;
  rowSchema: any;
  schema: any;
  dated?: Boolean;
}) {
  const { auth: authAll } = useContext(UserContext);
  const auth = objectOrJSONparse(authAll?.["registers"]);

  const [date, setDate] = useState(
    dated ? new Date()?.toISOString()?.slice(0, 10) : undefined
  );
  const [page, setPage] = useState(0);
  const [editor, setEditor] = useState(false);
  const [editData, setEditData] = useState(false);
  const [activeView, setActiveView] = useState<undefined | Boolean>(true);
  const [viewType, setViewType] = useState(
    source === "lsi_bookings" ? "schedule" : "table"
  );
  const [expanded, setExpanded] = useState();

  const [orderColumn, setOrderColumn] = useState();
  const [orderASC, setOrderASC] = useState(true);

  const [updates, setUpdates] = useState(0);

  const [showFilters, setShowFilters] = useState(false);
  const [customQuery, setCustomQuery] = useState<any>({});

  const rpp = rowsPerPage ? rowsPerPage : 10;
  console.log(customQuery);
  //fetch data by source
  const f = useGeneric({
    name: source,
    limit: !dated ? rpp : 1000,
    offset: !dated ? page : 0,
    reload: true,
    secondsToReload: 60,
    update: updates,
    query: {
      ...(query ? query : undefined),
      active: activeView,
      ...(customQuery ? customQuery : undefined),
      date: dated ? date?.slice(0,10) : undefined
    },
    order: orderColumn && {
      by: orderColumn,
      dir: orderASC ? "ASC" : "DESC",
    },
    showLoadingOnManualUpdate: true,
    ...(orderColumn || Object.keys(customQuery)?.length
      ? {}
      : schema()?.defaultParams),
    //process: process,
    ...params,
  });
  const rows = !f?.loading && f?.rows;

  const { filters, timeline, form, activeFilter, preSort } = schema?.(rows);

  const count = f?.count;

  const pages = Math.ceil(count / rpp);

  const functions = {
    showFunction: setEditor,
    setUpdates: setUpdates,
  };

  useEffect(() => {
    if (pages && !rows?.length) {
      // setPage(pages - 1);
      setPage(0);
    }
  }, [rows?.length, pages, updates]);

  useEffect(() => {
    setUpdates((prev: number) => prev + 1);
  }, [orderColumn, orderASC]);

  useEffect(() => {
    setOrderColumn(undefined);
    setCustomQuery({});
  }, [source]);

  useEffect(() => {
    console.log(viewType);
    setEditor(false);
    setActiveView(true);
    setViewType((prev: string) =>
      source === "lsi_bookings"
        ? "schedule"
        : prev === "schedule"
        ? "table"
        : prev
    );
  }, [source]);

  useEffect(() => {
    if (window.screen.width < 800) {
      setViewType("cards");
    }
  }, []);

  const contextValues = {
    rows: preSort ? preSort : rows,
    auth: auth,
    schema: schema,
    functions: functions,
    rowSchema: rowSchema,
    setOrderASC: setOrderASC,
    setOrderColumn: setOrderColumn,
    orderASC: orderASC,
    orderColumn: orderColumn,
    editor: editor,
    setEditor: setEditor,
    setEditData: setEditData,
    setUpdates: setUpdates,
    source: source,
    expanded: expanded,
    setExpanded: setExpanded,
    activeFilter: activeFilter
  };

  return (
    <RegisterViewContext.Provider value={contextValues}>
      <div>
        {editor && schema && form ? (
          <div className="editor-wrap">
            {schema(editData, functions, auth)?.form}
          </div>
        ) : (
          <>
            <div className="registers-top-panel">
              {heading ? <div className="heading">{heading}</div> : <></>}
              {dated ? (
                <DateSwitch date={date} setDate={setDate} />
              ) : (
                <Pagination
                  rows={rows}
                  page={page}
                  pages={pages}
                  setPage={setPage}
                />
              )}
              <div className="top-options">
                {schema === false ? (
                  <></>
                ) : editor ? (
                  <div onClick={() => setEditor(false)}>Zamknij edycję</div>
                ) : (
                  <>
                    {form ? (
                      <div
                        className="option-with-icon"
                        onClick={async () => {
                          setEditData(false);
                          setEditor(true);
                        }}
                      >
                        <img src={newIcon} />
                        <div>Nowa pozycja</div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {timeline ? (
                      <div
                        onClick={() =>
                          setViewType((prev: string) =>
                            prev !== "schedule" ? "schedule" : "table"
                          )
                        }
                      >
                        HARMONOGRAM
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className="option-with-icon"
                      onClick={() =>
                        setViewType((prev: string) =>
                          prev === "table" ? "cards" : "table"
                        )
                      }
                    >
                      <img src={viewSwitchIcon} />
                      <div>Zmień widok</div>
                    </div>
                    {source === "errorTable" ? (
                      <div
                        className="option-with-icon"
                        onClick={() => {
                          setCustomQuery((prev: any) => ({
                            ...prev,
                            resolved:
                              prev?.resolved === undefined
                                ? 0
                                : !prev.resolved
                                ? !prev.resolved
                                : undefined,
                          }));
                        }}
                      >
                        <img src={filterIcon} />
                        <div>
                          {customQuery &&
                          customQuery?.resolved === undefined ? (
                            <>Wszystkie</>
                          ) : !customQuery?.resolved ? (
                            <>Otwarte</>
                          ) : (
                            <>Rozwiązane</>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {filters ? (
                      <div
                        className={
                          "option-with-icon" +
                          (showFilters ? " deactivate" : "")
                        }
                        onClick={() => {
                          if (showFilters) {
                            setCustomQuery({});
                          }
                          setShowFilters((prev: Boolean) => !prev);
                        }}
                      >
                        <img src={filterIcon} />
                        <div>
                          {!showFilters ? <>Filtruj</> : <>Wyłącz filtry</>}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {activeFilter === false ? (
                      <></>
                    ) : (
                      <div
                        className="option-with-icon"
                        onClick={() => {
                          setActiveView((prev: any) => {
                            switch (prev) {
                              case undefined:
                                return true;
                                break;
                              case true:
                                return false;
                                break;
                              case true:
                                return undefined;
                                break;
                            }
                          });
                          setPage(0);
                        }}
                      >
                        <img
                          src={activeSwitchIcon}
                          style={{
                            opacity:
                              activeView === undefined
                                ? 1
                                : activeView === false
                                ? 0.3
                                : 0.8,
                          }}
                        />
                        <div>
                          {activeView === undefined
                            ? "wszystkie"
                            : activeView === false
                            ? "tylko nieaktywne"
                            : "tylko aktywne"}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {showFilters && schema ? (
          <Filters schema={filters} setQuery={setCustomQuery} />
        ) : (
          <></>
        )}

        {editor && schema && form ? (
          <></>
        ) : f.loading ? (
          <div style={{ padding: "20px" }}>
            <Loading little={true} />
          </div>
        ) : rows && rows?.length ? (
          viewType === "table" ? (
            <ViewTable />
          ) : viewType === "cards" ? (
            <ViewCards />
          ) : viewType === "schedule" ? (
            timeline ? (
              <ViewTimeline />
            ) : (
              <></>
            )
          ) : (
            <></>
          )
        ) : (
          <div className="pretty-info">Brak znalezionych wpisów.</div>
        )}
        {editor && schema && form ? (
          <></>
        ) : (
          <div
            style={{
              boxShadow: "0 0 2px rgba(0,0,0,.3)",
              position: pages > 1 ? "sticky" : "relative",
              background: "white",
              padding: "0 15px",
              bottom: 0,
              zIndex: "2",
              float: "right",
            }}
          >
            {dated ? (
              <></>
            ) : (
              <Pagination
                rows={rows}
                page={page}
                pages={pages}
                setPage={setPage}
              />
            )}
          </div>
        )}
      </div>
    </RegisterViewContext.Provider>
  );
}
