import { useContext, useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { ViewContext } from "../contexts/ViewContext";
import prettyDate from "../functions/prettyDate";
import { ReactComponent as Level1 } from "../../images/plans/level1.svg";
import { ReactComponent as Level2 } from "../../images/plans/level2.svg";
import planIcon from "../../images/icons/plan.svg";
import tableIcon from "../../images/icons/table.svg";
import RoomDetails from "./RoomDetails";
import Draggable from "react-draggable";
import RoomsTable from "./RoomsTable";
import { Link, useLocation } from "react-router-dom";
import HamsterLoading from "../common/HamsterLoading";
import RoomCards from "./RoomCards";
import loadingSpinner from "../../images/loading.svg";
import Loading from "../common/Loading";

export function roomBookings(bookings: any, d: any): any {
  return bookings?.filter((b: any) => {
    const now = new Date();
    const past = new Date(b?.data_do);
    const timeTo = b?.godzina_do?.slice(0, 5).split(":");
    past.setHours(timeTo?.[0], timeTo?.[1]);

    const isPast = now.getTime() - past.getTime() > 0;
    // console.log(now, past, isPast);
    return (
      parseInt(b?.numer) == parseInt(d?.numer) &&
      !b?.numer_rezygnacji &&
      !isPast
    );
  });
}

export default function RoomsView() {
  const { setCurrentPage } = useContext(ViewContext);
  setCurrentPage("rooms");
  const [filters, setFilters] = useState("all");
  const [lastUpdate, setLastUpdate] = useState<any>();
  const [currentPreview, setCurrentPreview] = useState<any>();

  const location = useLocation();
  const isViz = location.pathname.match(/\/plan[\/]?$/) ? true : false;
  const [viz, setViz] = useState<Boolean>(isViz);
  const [vizFloor, setVizFloor] = useState(10);
  const { modal, setModal } = useContext(ViewContext);

  const fetchData = useGeneric({
    name: "lsi_rooms",
    limit: 2000,
    reload: true,
    secondsToReload: 60,
    groupBy: (v: any) => v?.numer,
  });

  const fetchBookings = useGeneric({
    name: "lsi_bookings_today",
    limit: 500,
    reload: true,
    secondsToReload: 300,
    groupBy: (v: any) => v?.numer,
  });

  const bookings = !fetchBookings?.loading
    ? fetchBookings?.rows?.flat()
    : undefined;

  console.log(fetchData);
  const data = !fetchData?.loading
    ? fetchData.rows
        ?.map((f: any) => f?.[0])
        ?.flat()
        ?.sort((a: any, b: any) => Number(a?.numer) - Number(b?.numer))
    : undefined;
  const filtered =
    data &&
    data?.filter((r: any) =>
      filters === "occupied"
        ? r.status.match(/zajety/)
        : filters === "available"
        ? r.status.match(/wolny/)
        : true
    );
  const occupied = data?.filter((r: any) => r.status.match(/zajety/));
  const available = data?.filter((r: any) => r.status.match(/wolny/));

  const floor1 = filtered?.filter((r: any) => r.id_grupy_pokoi == 2);
  const floor1n = filtered?.filter((r: any) => r.id_grupy_pokoi == 5);
  const floor2 = filtered?.filter((r: any) => r.id_grupy_pokoi == 3);
  const floor2n = filtered?.filter((r: any) => r.id_grupy_pokoi == 6);

  // console.log(fetchData);
  useEffect(() => {
    setLastUpdate((prev: any) => {
      return new Date();
    });
    // console.log("updated");
  }, [fetchData?.time]);

  let waitHide: any;
  useEffect(() => {
    if (!fetchData?.loading) {
      const allRooms = filtered;
      allRooms?.forEach((d: any) => {
        const r = d?.numer?.replace(/ /g, "");
        const room = document.querySelector("#xroom" + r);
        const booked = roomBookings(bookings, d)?.length;
        // console.log(r, room);
        if (booked) {
          room?.classList.add("booked");
        }
        room?.addEventListener("click", () => {
          // console.log("clik");
          setModal({
            show: true,
            heading: "Podgląd pokoju",
            content: (
              <RoomDetails d={d} b={bookings} bl={fetchBookings?.loading} />
            ),
          });
        });

        room?.addEventListener("mouseover", () => {
          // console.log(d);
          clearTimeout(waitHide);
          setCurrentPreview(d);
        });
        room?.addEventListener("mouseout", () => {
          // console.log(d);
          waitHide = setTimeout(() => setCurrentPreview(undefined), 300);
        });
      });
      const dirtyRooms = filtered
        ?.filter((f: any) => f.stan == "1")
        ?.map((d: any) => d.numer.replace(/ /g, ""));
      occupied?.forEach((d: any) => {
        const r = d?.numer?.replace(/ /g, "");
        const el = document.querySelector("#xroom" + r);
        el?.classList.add("occupied");
        // el?.setAttribute("title", "zajęty do " + d.data_do );
        const title = document.createElement("title");
        title.textContent = "zajęty do " + d.data_do;
        el?.appendChild(title);
      });
      dirtyRooms?.forEach((r: any) => {
        const el = document.querySelector("#xroom" + r);
        el?.classList.add("dirty");
        el?.setAttribute("title", "wolny - brudny");
        // console.log(r, el);
      });
    }
  }, [viz, vizFloor, fetchData?.loading, fetchBookings?.loading]);

  return filtered ? (
    <div className="roomsWrap">
      <div className="rooms-top">
        <div className="rooms-top-main">
          <h1
            style={{
              background:
                "linear-gradient(168deg, rgb(29 153 141), rgb(157 34 255))",
              fontSize: "18px",
              padding: "0px 5px",
              backgroundClip: "text",
              color: "transparent",
              marginTop: 0,
              marginBottom: 0,
              marginRight: "20px",
            }}
          >
            Pokoje
          </h1>
          <div
            className="filters"
            onClick={() => setViz((prev: Boolean) => !prev)}
          >
            <Link to={viz ? "./" : "./plan"}>
              <div className="switch-view-button">
                {viz ? (
                  <>
                    <img
                      src={tableIcon}
                      style={{ width: "15px", marginRight: "8px" }}
                    />
                    <div>Do tabeli</div>
                  </>
                ) : (
                  <>
                    <img
                      src={planIcon}
                      style={{ width: "14px", marginRight: "8px" }}
                    />
                    <div>Do planu</div>
                  </>
                )}
              </div>
            </Link>
          </div>
          {viz ? (
            <div className="filters">
              <div
                className={vizFloor === 10 ? "active" : ""}
                onClick={() => setVizFloor(10)}
              >
                Całość
              </div>
              <div
                className={vizFloor === 1 ? "active" : ""}
                onClick={() => setVizFloor(1)}
              >
                Piętro I
              </div>
              <div
                className={vizFloor === 2 ? "active" : ""}
                onClick={() => setVizFloor(2)}
              >
                Piętro II
              </div>
            </div>
          ) : (
            <div className="filters">
              <div
                className={filters === "all" ? "active" : ""}
                onClick={() => setFilters("all")}
              >
                Wszystkie
              </div>
              <div
                className={filters === "occupied" ? "active" : ""}
                onClick={() => setFilters("occupied")}
              >
                Zajęte
              </div>
              <div
                className={filters === "available" ? "active" : ""}
                onClick={() => setFilters("available")}
              >
                Wolne
              </div>
            </div>
          )}
          <div className="room-stats">
            <div>
              {/* Obłożenie:{" "} */}
              <span style={{ fontSize: "120%" }}>{occupied.length}</span> /{" "}
              <span style={{ fontSize: "100%" }}>{data.length}</span>
              <span
                style={{
                  color: "brown",
                  display: "inline-block",
                  fontSize: "120%",
                  marginLeft: "6px",
                }}
              >
                {String(
                  Math.round((occupied.length / data.length) * 100 * 10) / 10
                )?.replace(".", ",")}{" "}
                %
              </span>
              <div
                style={{
                  background: "whitesmoke",
                  width: "33px",
                  display: "inline-flex",
                  alignItems: "center",
                  margin: "0 10px",
                  boxShadow: "0 0 1px inset rgba(0,0,0,.73)",
                  overflow: "hidden",
                  borderRadius: "5px",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    background: "orangered",
                    height: "10px",
                    borderRadius: "0px",
                    boxShadow: "0 0 4px inset rgba(0,0,0,.33)",
                    width:
                      String(
                        Math.round((occupied.length / data.length) * 100 * 10) /
                          10 /
                          3
                      ) + "px",
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "2px 9px",
            background: "#d1fa7e",
            borderRadius: "5px",
            alignItems: "center",
          }}
        >
          {fetchBookings?.loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={loadingSpinner}
                width={21}
                style={{ paddingRight: "10px" }}
              />{" "}
              POBIERANIE REZERWACJI
            </div>
          ) : (
            <div>✓</div>
          )}
        </div>
        <div className="last-update">Stan na {prettyDate(lastUpdate)}</div>
      </div>
      {viz ? (
        <div className="viz-rooms">
          {vizFloor === 1 ? (
            <div>
              <Level1 />
            </div>
          ) : vizFloor === 2 ? (
            <div>
              <Level2 />
            </div>
          ) : (
            <div className="viz-overall-wrap">
              <div className="viz-overall">
                <div>
                  <h2
                    className="grid-heading"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVizFloor(1)}
                  >
                    Piętro I
                  </h2>
                  <Level1 />
                </div>
                <div>
                  <h2
                    className="grid-heading"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVizFloor(2)}
                  >
                    Piętro II
                  </h2>
                  <Level2 />
                </div>
              </div>
              <div className="room-list">
                <div>
                  <div>Zajęte</div>
                  <div>
                    {occupied?.map((r: any, index: number, all: any[]) => (
                      <>
                        {index !== 0 &&
                        all?.[index - 1]?.id_grupy_pokoi !==
                          r?.id_grupy_pokoi ? (
                          <br />
                        ) : (
                          <></>
                        )}
                        <span
                          onClick={() => {
                            setModal({
                              show: true,
                              heading: "Podgląd pokoju",
                              content: (
                                <RoomDetails
                                  d={r}
                                  b={bookings}
                                  bl={fetchBookings?.loading}
                                />
                              ),
                            });
                          }}
                          className={
                            currentPreview?.numer === r?.numer ? "active" : ""
                          }
                          onMouseOver={() => {
                            clearTimeout(waitHide);
                            setCurrentPreview(r);
                          }}
                          onMouseOut={() => {
                            waitHide = setTimeout(
                              () => setCurrentPreview(undefined),
                              200
                            );
                          }}
                        >
                          {r?.numer}
                        </span>
                      </>
                    ))}
                  </div>
                </div>
                <div>
                  <div>Wolne</div>
                  <div>
                    {available?.map((r: any, index: number, all: any[]) => (
                      <>
                        {index !== 0 &&
                        all?.[index - 1]?.id_grupy_pokoi !==
                          r?.id_grupy_pokoi ? (
                          <br />
                        ) : (
                          <></>
                        )}
                        <span
                          onClick={() => {
                            setModal({
                              show: true,
                              heading: "Podgląd pokoju",
                              content: (
                                <RoomDetails
                                  d={r}
                                  b={bookings}
                                  bl={fetchBookings?.loading}
                                />
                              ),
                            });
                          }}
                          onMouseOver={() => {
                            clearTimeout(waitHide);
                            setCurrentPreview(r);
                          }}
                          onMouseOut={() => {
                            waitHide = setTimeout(
                              () => setCurrentPreview(undefined),
                              200
                            );
                          }}
                          className={
                            (currentPreview?.numer === r?.numer
                              ? "active"
                              : "") +
                            (r?.status?.match(/brudny/) ? " dirty" : "") +
                            (roomBookings(bookings, r)?.length
                              ? " booked-later"
                              : "")
                          }
                        >
                          {r?.numer}
                        </span>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="room-list"
                style={{
                  background: "rgba(240, 231, 183, 0.792)",
                }}
              >
                <div style={{ padding: "2px 32px" }}>
                  Brudne:{" "}
                  {
                    filtered?.filter((r: any) => r?.status?.match(/brudny/))
                      ?.length
                  }
                </div>
              </div>
            </div>
          )}
          <Draggable>
            <div className={"room-preview" + (vizFloor === 10 ? " all" : "")}>
              {currentPreview && (
                <div>
                  <div style={{ fontSize: "120%", color: "rgb(144, 149, 85)" }}>
                    Pokój {currentPreview?.numer?.replace(/ /, "")}
                  </div>
                  {!currentPreview?.first_name ? (
                    <div>{currentPreview?.status}</div>
                  ) : (
                    <></>
                  )}
                  <div className="guest-full-name">
                    {(
                      <span style={{ opacity: 0.6 }}>
                        {roomBookings(bookings, currentPreview)?.[0]?.gosc}
                      </span>
                    ) || (
                      <>
                        {currentPreview?.first_name?.toLowerCase()}{" "}
                        {currentPreview?.last_name?.toLowerCase()}
                      </>
                    )}
                  </div>

                  <div style={{ fontSize: "14px", padding: "5px 0" }}>
                    {roomBookings(bookings, currentPreview)?.[0]?.godzina_od ? (
                      <div>
                        Rezerwacja od{" "}
                        {roomBookings(
                          bookings,
                          currentPreview
                        )?.[0]?.godzina_od?.slice(0, 5)}
                      </div>
                    ) : currentPreview?.data_od || currentPreview?.data_do ? (
                      <>
                        <div>
                          {prettyDate(currentPreview?.data_od, "", false, {
                            weekday: "long",
                          })}
                        </div>
                        <div>
                          {" > "}{" "}
                          {prettyDate(currentPreview?.data_do, "", false, {
                            weekday: "long",
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {currentPreview?.uwaga ? (
                    <div
                      style={{
                        background: "whitesmoke",
                        borderRadius: "4px",
                        padding: "3px 3px",
                        fontSize: "12px",
                        color: "black",
                        maxWidth: "250px",
                      }}
                    >
                      {currentPreview?.uwaga}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
          </Draggable>
        </div>
      ) : (
        <>
          <div className="rooms-tables">
            <RoomsTable
              data={floor1}
              bookings={bookings}
              bookingsLoading={fetchBookings?.loading}
              heading={"Piętro I"}
            />
            <RoomsTable
              data={floor2}
              bookings={bookings}
              bookingsLoading={fetchBookings?.loading}
              heading={"Piętro II"}
            />
            <RoomsTable
              data={floor1n}
              bookings={bookings}
              bookingsLoading={fetchBookings?.loading}
              heading={"Piętro I nowa część"}
            />
            <RoomsTable
              data={floor2n}
              bookings={bookings}
              bookingsLoading={fetchBookings?.loading}
              heading={"Piętro II nowa część"}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <Loading />
  );
}
