import { useContext, useState } from "react";
import { ViewContext } from "../contexts/ViewContext";
import { URL, URLProd } from "../paths";
import imageIcon from "../../images/icons/picture.svg";
import pdfIcon from "../../images/icons/pdf-file.svg";
import saveIcon from "../../images/icons/save.svg";
import Preview from "./Preview";
import FileUploadListItem from "./FileUploadListItem";

export default function FileUploadListMini({ files }: { files: any }) {
  const { modal2, setModal2 } = useContext(ViewContext);
  const [preview, setPreview] = useState(false);

  return (
    <div className="file-list-mini">
      {files?.map((f: any) => {
        const fileNameSoleWithExt = f.name.replace(/.*_(.*)/, "$1");
        const fileNameSole = fileNameSoleWithExt.replace(/(\..*)(?!.*\.)/, "");
        const link = URLProd + "uploads/files/" + f?.path;
        return <FileUploadListItem module={f?.module} subModule={f?.subModule}
        file={f}/>
        // return (
        //   <div className="file-list-mini-item">
        //     {f.format.match(/image/) ? (
        //       <img
        //         src={imageIcon}
        //         onClick={() =>
        //           setModal2({
        //             show: true,
        //             heading: "Podgląd",
        //             content: <Preview link={link} />,
        //           })
        //         }
        //       />
        //     ) : f?.format === "application/pdf" ? (
        //       <a href={link} target="_blank">
        //         <img src={pdfIcon} />
        //       </a>
        //     ) : (
        //       <></>
        //     )}

        //     <div style={{ width: "100%" }}>{fileNameSoleWithExt}</div>
        //     {/* <div className="save">
        //       <a target="_blank" href={link} download={fileNameSoleWithExt}>
        //         Zapisz
        //       </a>
        //     </div> */}
        //   </div>
        // );
      })}
    </div>
  );
}
