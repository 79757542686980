import { multiAddColumn, multiAddProps } from "../../forms_new/formContentType";
import prettyDate from "../../functions/prettyDate";
import { isSameDay } from "../../schedules/ScheduleTable";

export function getPropByDate(event: any, prop: string, date: any) {
  return event.dates && JSON.parse(event.dates)
    ? JSON.parse(event.dates).find((d: any) => isSameDay(d.date, date))?.[prop]
    : "0";
}

export const yesOrNo = [
  { id: 1, name: "nie", prefix: <span style={{ color: "brown" }}>✖</span> },
  { id: 2, name: "tak", prefix: <span style={{ color: "#0bcb00" }}>✓</span> },
];

export default function idToName(options: any[], id: number | string) {
  // console.log(id);
  const flatOptions = flattenOptions(options);
  return (
    flatOptions &&
    flatOptions[flatOptions.map((m: any) => m.id)?.indexOf(id)]?.name
  );
}

export function scheduleCurrent(values: any) {
  const schedules: any = [];
  Object.keys(values).forEach((k: any) => {
    if (k.match(/schedule%.*%/)) {
      schedules?.push(values[k] && JSON.parse(values[k]));
    }
  });
  return schedules;
}

export function dateFromDatedName(name: string) {
  return name?.replace(/(.*)%(.*)%/, "$2");
}

export function getDatedFormValue(
  values: any,
  prop: string,
  date: string | Date
) {
  const d = new Date(date).toISOString().slice(0, 10);
  let value = "";
  Object.keys(values).forEach((k: any) => {
    const regex = new RegExp(`${prop}%${d}%`);
    if (k.match(regex)) {
      value = values[k] && JSON.parse(values[k]);
    }
  });
  return value;
}

export function pickDatedFormValues(values: any, propName: string) {
  return Object.keys(values)
    ?.filter((key: string) => {
      const vals = key.match(/(.*)%(.*)%/);
      const name = vals?.[1];
      const date = vals?.[2];
      return name && date && name === propName;
    })
    ?.map((k: string) => {
      const vals = k.match(/(.*)%(.*)%/);
      const name = vals?.[1];
      const date = vals?.[2];
      return {
        name: name || "",
        date: date || "",
        dateShort:
          date &&
          new Date(date).toLocaleDateString("pl-PL", {
            day: "numeric",
            month: "2-digit",
          }),
        value: values?.[k] || "",
      };
    });
}

export function flattenOptions(options: any) {
  return options
    .map((m: any) => (m.group ? m.options.map((o: any) => o) : m))
    .flat();
}

export function idToJSX(options: any[], id: number | string) {
  const flatOptions = flattenOptions(options);
  return (
    flatOptions &&
    flatOptions[flatOptions.map((m: any) => m.id)?.indexOf(id)]?.jsx
  );
}

export const key_info_types = [
  { id: 0, name: "" },
  {
    id: 1,
    name: "Ogólne",
  },
  {
    id: 2,
    name: "Ustawienie sali",
  },
  {
    id: 3,
    name: "Specyfikacja",
  },
  {
    id: 4,
    name: "Alkohole",
  },
  {
    id: 5,
    name: "Urządzenia",
  },
  {
    id: 6,
    name: "Muzyka",
  },
  {
    id: 7,
    name: "Atrakcje",
  },
];

export const music_type = [
  { id: 0, name: "brak", jsx: <>brak muzyki</> },
  { id: 1, name: "cicha", jsx: <>cicha muzyka do posiłku</> },
  {
    id: 2,
    name: "głośna z nośnika",
    jsx: (
      <>
        <strong>głośna</strong> muzyka z nośnika
      </>
    ),
  },
  {
    id: 3,
    name: "głośna DJ/zespół",
    jsx: (
      <>
        <strong>głośna</strong> muzyka <strong>DJ/zespół</strong>
      </>
    ),
  },
];

export const dish_type = [
  { id: 0, name: "brak", jsx: <></> },
  { id: 1, name: "tylko przerwa kawowa", jsx: <>tylko przerwa kawowa</> },
  { id: 2, name: "obiad bez przystawek", jsx: <>obiad bez przystawek</> },
  { id: 3, name: "obiad z przystawkami", jsx: <>obiad z przystawkami</> },
  { id: 11, name: "zupa", jsx: <>zupa</> },
];

export const miscellanea = [
  {
    id: 1,
    name: "Dorośli",
  },
  {
    id: 2,
    name: "Dzieci",
  },
  {
    id: 3,
    name: "Kelnerzy",
  },
  {
    id: 4,
    name: "Kucharze",
  },
  {
    id: 5,
    name: "Zmywak",
  },
  {
    id: 17,
    name: "Obsługa",
  },
  {
    id: 6,
    name: "Wiejski stół",
  },
  {
    id: 7,
    name: "Drink bar",
  },
  {
    id: 8,
    name: "ZAIKS",
  },
  {
    id: 9,
    name: "Parking",
  },
  {
    id: 10,
    name: "Wynajem sali",
  },
  {
    id: 11,
    name: "Przerwa kawowa",
  },
  {
    id: 12,
    name: "Lunch",
  },
  {
    id: 15,
    name: "Kolacja",
  },
  
  {
    id: 13,
    name: "Deser",
  },
  {
    id: 14,
    name: "Saldo konto restauracja",
  },
  {
    id: 16,
    name: "Opłata korkowa",
  },
  {
    id: 18,
    name: "Lód",
  },
  /*  {
    id: 13,
    name: "Inne",
  },*/
];
export const meal_sub = [
  { value: "snacks", name: "Przystawki zimne" },
  { value: "meals", name: "" },
  { value: "drinks", name: "Napoje" },
];

export const schedule_item_type = [
  {
    group: "Posiłek",
    options: [
      { name: "Posiłki", id: 1 },
      { name: "Śniadanie", id: 2 },
      { name: "Lunch", id: 3 },
      { name: "Obiad", id: 4 },
      { name: "Podwieczorek", id: 5 },
      { name: "Kolacja", id: 6 },
      { name: "Przerwa kawowa", id: 7 },
      { name: "Przekąski", id: 8 },
      { name: "Bufet", id: 9 },
      { name: "Kanapki", id: 10 },
    ],
  },
];

export const meal_type = [
  {
    group: "Szablony",
    options: [
      {
        id: 101,
        name: "Przerwa kawowa",
        content: "kawa, herbata, soki, woda, ciasteczka kruche",
      },
    ],
  },
  {
    group: "Jedzenie",
    options: [
      {
        id: 1,
        name: "Starter",
      },
      {
        id: 2,
        name: "Zupa",
      },
      {
        id: 3,
        name: "Danie główne",
      },
      {
        id: 4,
        name: "Deser",
      },
      {
        id: 5,
        name: "Przekąska gorąca",
      },
      {
        id: 6,
        name: "Przekąska zimna",
      },
      {
        id: 7,
        name: "Śniadanie",
      },
      {
        id: 8,
        name: "Tort",
      },
    ],
  },
  {
    group: "Napoje",
    options: [
      {
        id: 21,
        name: "Napoje zimne",
      },
      {
        id: 22,
        name: "Napoje gorące",
      },
      {
        id: 31,
        name: "Alkohole",
      },
    ],
  },
];

export const special_options = [
  {
    id: 1,
    name: "dla dzieci",
    rowStyle: {
      background: "#e8e5db",
    },
  },
  {
    id: 2,
    name: "bezglutenowe",
    rowStyle: {
      background: "#dbe8e8",
    },
  },
  {
    id: 3,
    name: "wegetariańskie",
    rowStyle: {
      background: "#dbe8df",
    },
  },
  {
    id: 4,
    name: "wegańskie",
    rowStyle: {
      background: "#e2f0d4",
    },
  },
];

export const lsi_restaurant: multiAddColumn = {
  name: "",
  element: "hybrid",
  style: {
    width: "40px",
    verticalAlign: "center",
  },
  schema: {
    name: "lsi_restaurant",
    db: "lsi_restaurant",
    query: (input: string | number, rowData: any) => ({
      nazwa: input,
      category: rowData.category, //"drink"
    }),
    resultElement: (item: any) => (
      <div
        style={{
          width: "400px",
          display: "flex",
          justifyContent: "space-between",
          color: item.priority == 0 ? "brown" : "black",
        }}
      >
        <div style={{ textAlign: "left" }}>{item.nazwa}</div>
        <div style={{ fontSize: "8px" }}>{item.GrupaAdm}</div>
        <div style={{ fontSize: "8px" }}>
          {prettyDate(item._datamodyfikacji)}
        </div>
      </div>
    ),
    resultUpdateSub: (item: any) => [["name", item.nazwa]],
  },
};
