import { useContext } from "react";
import editIcon from "../../images/icons/pencil.png";
import { ViewContext } from "../contexts/ViewContext";
import prettyDate from "../functions/prettyDate";
import { voucherStatus } from "./voucherStatus";
import insertGeneric from "../dataFetch/insertGeneric";
import { sqlCurrentDate } from "../functions/tools";

export default function VoucherGroupOptions({
  items,
  setUpdate,
}: {
  items: any;
  setUpdate: Function;
}) {
  const { setModal } = useContext(ViewContext);
  return (
    <div>
      <img
        onClick={() =>
          setModal({
            content: (
              <div style={{ padding: "10px" }}>
                {items && items?.length ? (
                  <>
                    <div className="grid-2">
                      <div>Vouchery</div>{" "}
                      <div>
                        od <strong>{items.at(-1).number_text}</strong> do{" "}
                        <strong>{items.at(0).number_text}</strong>
                      </div>
                    </div>
                    <div className="grid-2">
                      <div>Zamówił(a):</div>
                      <strong>{items.at(0)?.request_user}</strong>
                    </div>
                    <div className="grid-2">
                      <div>Ważne do:</div>

                      <strong>
                        {prettyDate(items.at(0)?.valid_until, "Dziś,", true)}
                      </strong>
                    </div>
                    <div className="grid-2">
                      <div>Status:</div>{" "}
                      <strong style={{ color: "#5c500f" }}>
                        {voucherStatus?.[items.at(0)?.status]}
                      </strong>
                    </div>
                    {items?.at(0)?.status === 1 ? (
                      <div>
                        <button
                          className="normal-button"
                          style={{
                            width: "100%",
                            padding: "12px 12px",
                            marginBlock: "15px",
                          }}
                          onClick={() => {
                            insertGeneric("set-voucher-status", {
                              action_date: sqlCurrentDate(),
                              status: 2,
                              group_id: items?.at(0)?.group_id
                            });
                            setUpdate((prev: number) => prev + 1);
                            setModal((prev: any) => ({ ...prev, show: false }));
                          }}
                        >
                          Zmień status na <strong>PRZYJĘTE W HOTELU</strong>
                        </button>
                      </div>
                    ) : (
                        <button
                        className="normal-button"
                        style={{
                          width: "100%",
                          padding: "12px 12px",
                          marginBlock: "15px",
                        }}
                        onClick={() => {
                          insertGeneric("set-voucher-status", {
                            action_date: sqlCurrentDate(),
                            status: 1,
                            group_id: items?.at(0)?.group_id
                          });
                          setUpdate((prev: number) => prev + 1);
                          setModal((prev: any) => ({ ...prev, show: false }));
                        }}
                      >
                        Przywróć status <strong>WYDRUKOWANE (nieprzyjęte)</strong>
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            ),
            heading: <>Pakiet voucherów</>,
            show: true,
          })
        }
        src={editIcon}
        className="clickable-icon"
      />
    </div>
  );
}
