import { useState } from "react";
import VoucherList from "./VoucherList";
import vIcon from "../../images/icons/voucher.svg";
import { ViewContext } from "../contexts/ViewContext";


export default function VoucherRegister({
  items,
  register,
  auth,
  setUpdate,
}: {
  items: any;
  register: any;
  auth: number;
  setUpdate: Function;
}) {
  const i = auth === 9 ? [...items?.slice()?.reverse(), ...register] : register;

  const [selected, setSelected] = useState("100");
  const [selectedG, setSelectedG] = useState("100");
  const [paidMode, setPaidMode] = useState(true);

  const viewMap: any = (paid: 0 | 1) => {
    return {
      "50": (
        <VoucherList
          key={50 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="50 zł"
          amount={50}
          paid={paid}
        />
      ),
      "100": (
        <VoucherList
          key={100 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="100 zł"
          amount={100}
          paid={paid}
        />
      ),
      "150": (
        <VoucherList
          key={150 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="150 zł"
          amount={150}
          paid={paid}
        />
      ),
      "200": (
        <VoucherList
          key={200 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="200 zł"
          amount={200}
          paid={paid}
        />
      ),
    };
  };
  return (
    <div className="voucher-register">
      <div className="grid-1-3">
        <h1
          className="switch"
          onClick={() => setPaidMode((prev: boolean) => !prev)}
        >
          <img style={{width:"22px", marginRight:"8px" }} src={vIcon}/>
          {paidMode ? <>PŁATNE</> : <>GRATISOWE</>}{" "}
          
        </h1>
        <div className="buttons">
          {paidMode ? (
            <>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "50" ? " active" : "")
                }
                onClick={() => setSelected("50")}
              >
                50 zł
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "100" ? " active" : "")
                }
                onClick={() => setSelected("100")}
              >
                100 zł
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "150" ? " active" : "")
                }
                onClick={() => setSelected("150")}
              >
                150 zł
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "200" ? " active" : "")
                }
                onClick={() => setSelected("200")}
              >
                200 zł
              </button>
            </>
          ) : (
            <>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "50" ? " active" : "")
                }
                onClick={() => setSelectedG("50")}
              >
                50 zł
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "100" ? " active" : "")
                }
                onClick={() => setSelectedG("100")}
              >
                100 zł
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "150" ? " active" : "")
                }
                onClick={() => setSelectedG("150")}
              >
                150 zł
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "200" ? " active" : "")
                }
                onClick={() => setSelectedG("200")}
              >
                200 zł
              </button>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="voucher-lists">
          {paidMode ? viewMap(1)?.[selected] : viewMap(0)?.[selectedG]}
        </div>
      </div>
    </div>
  );
}
