import prettyDate from "../../../functions/prettyDate";

export function row(row?: any): any[] {
  return [
    {
      name: "Nr klienta",
      value: row?.NumerKlienta,
      className: "NumerKlienta",
    },
    {
      name: "Nazwisko",
      source: "Name",
      value: row?.Name,
    },
    {
      name: "Imię",
      source: "FirstName",
      value: row?.FirstName,
    },
    {
      name: "Nazwa firmy",
      source: "FirmName",
      value: row?.FirmName,
    },
    {
      name: "Miasto",
      source: "AddrCity",
      value: row?.AddrCity,
    },
    {
      name: "Ulica",
      source: "AddrStreet",
      value: `${row?.AddrStreet || "-"} ${row?.AddrLNo || ""} ${
        row?.AddrCountry && row?.AddrCountry != "Polska" ? row?.AddrCountry : ""
      }`,
    },
    {
      name: "Kod pocztowy",
      source: "AddrZIP",
      value: row?.AddrZIP,
    },
    {
      name: "Telefon",
      source: "Phone",
      value: row?.Phone,
    },
    {
      name: "Komórka",
      source: "TelefonKom",
      value: row?.TelefonKom,
    },
    {
      name: "Email",
      source: "Email",
      value: row?.Email,
    },
    {
      name: "Data ostatniej aktywności",
      source: "DataOstatniejAktywnosci",
      value: row?.DataOstatniejAktywnosci,
    },
  ];
}
