import { useContext } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { RegisterViewContext } from "./View";
import BookingCell from "./Timeline/BookingCell";
import BookingRowDate from "./Timeline/BookingRowDate";

export default function ViewTimeline() {
  const {
    rows,
    schema,
    rowSchema,
    setOrderASC,
    setOrderColumn,
    orderASC,
    orderColumn,
    editor,
    setEditor,
    setEditData,
    setUpdates,
    source,
  } = useContext(RegisterViewContext);

  const startDate = new Date();

  const dates: Date[] = [];

  for (let i = 0; i <= 7; i++) {
    const d = new Date(startDate);
    d.setDate(d.getDate() + i);
    dates.push(d);
  }

  const f = useGeneric({ name: "lsi_room_definitions" });
  const roomList = !f?.loading && f?.rows;
  console.log(rows);
  const rooms =
    roomList &&
    roomList.map((room: any) => ({
      ...room,
      bookings: rows.filter(
        (b: any) =>
          !b?.numer_rezygnacji &&
          Number(b?.numer.replace(/ /g, "")) === Number(room?.numer)
      ),
    }));

  return (
    <div className="timeline">
      <div className="timeline-head">
        <div className="head-title">Pokój</div>
        <div className="dates">
          {dates.map((d: Date) => (
            <div className="head-date">
              {d.toLocaleDateString("pl-PL", {
                day: "numeric",
                month: "long",
                weekday: "short",
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="timeline-rows">
        {rooms &&
          rooms.map((r: any) => (
            <div className="timeline-row">
              <div className="row-title">{r?.numer}</div>
              <div className="dates">
                {dates?.map((d: Date, index: number, all: Date[]) => (
                  <BookingRowDate
                    row={r}
                    date={d}
                    previous={all?.[index - 1]}
                    next={all?.[index + 1]}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
