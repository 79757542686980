import { useContext, useState } from "react";
import Search from "./Search";
import listIcon from "../../images/icons/iconmonstr-text-bullet-list-lined.svg";
import monthIcon from "../../images/icons/iconmonstr-calendar-7.svg";
import weekIcon from "../../images/icons/weekIcon3.svg";
import tableIcon from "../../images/icons/table.svg";
import searchIcon from "../../images/icons/iconmonstr-binoculars-7.svg";
import tvIcon from "../../images/icons/tv.svg";
import loupeIcon from "../../images/icons/loupe.svg";
import historyIcon from "../../images/icons/view.svg";
import { CalendarContext } from "../contexts/CalendarContext";

export default function CalendarSidePanel({
  mode,
  setMode,
  expanded,
  setExpanded,
  expandedMonth,
  setExpandMonth,
}: {
  mode: any;
  setMode: Function;
  expanded: any;
  setExpanded: Function;
  expandedMonth: Boolean;
  setExpandMonth: Function;
}) {
  const { auth } = useContext(CalendarContext);
  return (
    <div className={"left-panel" + (expanded ? " expanded" : "")}>
      <header className="mainHeader">
        <div className="mainInfo">
          {/* <div className="mainTitle">Kalendarz</div> */}
          <div className="viewSwitch">
            {window.screen.width > 900 ? (
              <div
                className={mode === "list" ? "active" : ""}
                onClick={() => {
                  setMode("list");
                  localStorage.setItem("calendar_mode", "list");
                }}
              >
                <img src={listIcon} />
                Lista
              </div>
            ) : (
              <></>
            )}
            <div
              className={mode === "table" ? "active" : ""}
              onClick={() => {
                setMode("table");
                localStorage.setItem("calendar_mode", "table");
              }}
            >
              <img src={tableIcon} />
              Tabela
            </div>
            <div
              className={mode === "month" ? "active" : ""}
              onClick={() => {
                setMode("month");
                localStorage.setItem("calendar_mode", "month");
              }}
            >
              <img src={monthIcon} />
              Miesiąc
              <div
                className="additional-left-option"
                onClick={() => {
                  setExpandMonth((prev: Boolean) => !prev);
                  localStorage.setItem(
                    "calendar_month_expanded",
                    String(!expandedMonth)
                  );
                }}
              >
                {expandedMonth ? "zwiń" : "rozwiń"}
              </div>
            </div>
            <div
              className={mode === "week" ? "active" : ""}
              onClick={() => {
                setMode("week");
                localStorage.setItem("calendar_mode", "week");
              }}
            >
              <img src={weekIcon} />
              Tydzień
            </div>
            <div
              className={mode === "kitchen" ? "active" : ""}
              onClick={() => {
                setMode("kitchen");
                localStorage.setItem("calendar_mode", "kitchen");
              }}
            >
              <img src={loupeIcon} />
              Dzień
            </div>
            <div
              className={mode === "tv" ? "active" : ""}
              onClick={() => {
                setMode("tv");
                localStorage.setItem("calendar_mode", "tv");
              }}
            >
              <img src={tvIcon} />
              TV
            </div>
            {auth?.beta ? (
              <div
                className={mode === "history" ? "active" : ""}
                onClick={() => {
                  setMode("history");
                  localStorage.setItem("calendar_mode", "history");
                }}
              >
                <img src={historyIcon} />
                Historia
              </div>
            ) : (
              <></>
            )}
            {auth?.search ? (
              <div
                className={
                  "searchButton" + (mode === "search" ? " active" : "")
                }
                onClick={() => {
                  // setExpanded((prev: Boolean) => !prev);
                  setMode("search");
                  localStorage.setItem("calendar_mode", "search");
                }}
              >
                <img src={searchIcon} />
                Szukaj
              </div>
            ) : (
              <></>
            )}

            {/* <div>
                 <StateButton
          text="Pokaż moje"
          icon={<img src={eyeIcon} />}
          stateSetter={() =>
            setCreatorHighlight((prev: boolean) => !prev)
          }
          activeState={false}
          refState={creatorHighlight}
        />
            </div> */}
          </div>
        </div>
      </header>{" "}
      {auth?.search ? (
        <div className={"search-expandable" + (expanded ? " visible" : "")}>
          <Search />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
