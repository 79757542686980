import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { objectOrJSONparse } from "../../../functions/tools";
import { registerSchema } from "../schemaType";
import expandIcon from "../../../../images/icons/expand.svg";
import detailsIcon from "../../../../images/icons/details.svg";
import RowMore from "./RowMore";
import { formSchema } from "./formSchemaMain";


export function schema(data: any, functions: any, auth: any): registerSchema {
  return {
    filters: [
      /*{
        column: "resolved",
        name: "Typ",
        element: "select",
        optionList: [
          {
            id: "0",
            name: "otwarte",
          },
          {
            id: "1",
            name: "zamknięte",
          },
        ],
      },*/
      {
        column: "lsi_document_number",
        name: "Nr dok. sprzedaży",
        element: "input",
      },
      {
        column: "purchaser_number_on_document",
        name: "Nabywca",
        element: "input",
      },
      {
        column: "guest",
        name: "Gość",
        element: "input",
      },
    ],
    name: "errors",
    defaultParams: {
      name: "errorTable",
      order: {
        by: ["resolved", "id"],
        dir: ["ASC", "DESC"],
      },
    },
    edit: false,
    activeFilter: false,
    remove: false,
    options: [
      {
        icon: detailsIcon,
        name: "entry-view",
        target: (row: any) => (
          <div>
            {row.entry_number}/{row.entry_year}
          </div>
        ),
        title: "Błąd - wszystkie informacje",
        style: {
          height: "18px",
        },
        linkType: "route",
      },
      {
        icon: expandIcon,
        name: "entry-options",
        target: (row: any) => <RowMore setUpdates={functions?.setUpdates} />,
        style: {
          height: "18px",
        },
        linkType: "below",
      },
      
    ],
    form: (
      <Form
        className="form-clear"
        name="errors"
        showFunction={functions?.showFunction}
        data={data}
        submit={async (payload: any) => {
          if (payload?.id === "") {
            delete payload.id;
          }
          console.log(payload);
          await insertGeneric("set-errors", payload);
          await functions?.setUpdates((prev: number) => prev + 1);
          functions?.showFunction(false);
        }}
        // defaultSubTab="main"
        schema={formSchema(data)}
      />
    ),
  };
}
