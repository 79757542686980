import Uppy from "@uppy/core";
import { DragDrop, StatusBar } from "@uppy/react";

import XHRUpload from "@uppy/xhr-upload";
// @ts-ignore
import Polish from "@uppy/locales/lib/pl_PL";
import { URL } from "../paths";
import { useEffect, useState } from "react";
import FileUploadListMini from "./FileUploadListMini";
import { useFormContext } from "react-hook-form";

export function prettyFileSize(size: number) {
  const kb = size / 1024;
  const mb = kb / 1024;
  if (mb < 0.5) {
    return Math.round(kb * 100) / 100 + " KB";
  } else {
    return Math.round(mb * 100) / 100 + " MB";
  }
}

export default function FileUpload({
  module,
  subModule,
  category,
  moduleID,
  setUpdates,
  subID,
  subDB,
  mini,
  name,
  formContext,
  fileData
}: {
  module: string;
  subModule: string;
  category?: string;
  moduleID: number;
  setUpdates?: Function;
  subID?: number;
  subDB?: string;
  mini?: Boolean;
  name?: string;
  formContext?: any;
  fileData?:any;
}) {
  const [uppy] = useState(() =>
    new Uppy({
      id: String(Math.random()),
      locale: Polish,
      onBeforeFileAdded: (currentFile: any, files: any) => {
        const name = Date.now() + "_" + currentFile.name;
        const modifiedFile = {
          ...currentFile,
          meta: {
            ...currentFile.meta,
            name,
            sub_id: subID,
            sub_db_name: subDB,
            relativePath: module + (subModule ? "/" + subModule : ""),
          },
          name,
        };
        uppy.log(modifiedFile.name);
        return modifiedFile;
      },

      restrictions: {
        maxFileSize: 32505856, // 31 MB
        maxTotalFileSize: 32505856, // 31 MB
        maxNumberOfFiles: 10,
        allowedFileTypes: [".pdf", "image/*", "audio/*", "video/*"],
      },
    }).use(XHRUpload, {
      endpoint: URL + "uploads/upload.php",
    })
  );
  const [files, setFiles] = useState<any>({ successful: [], failed: [] });

  const [fileNames, setFileNames] = useState<any>([]);

  const [fileList, setFileList] = useState<any>(fileData ? fileData : []);

  useEffect(() => {
    setFileList((prev:any) => fileData);
  }, [fileData?.length]);
  //   const [fileData, setFileData] = useState<any>([]);

  async function updateFileList(data: any) {
    const response = await fetch(`${URL}uploads/update_list.php`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(data),
    });
    const result = await response.json();
    setFileList((prev: any) => [...prev, ...data]);
    if (setUpdates) {
      setUpdates((prev: number) => prev + 1);
    }

    console.log(result, fileList);
  }

  function handleUpload(result: any) {
    setFiles({ successful: result.successful, failed: result.failed });
    // console.log(result);
    setFileNames(result.successful.map((f: any) => f.name));
    const fileData = result.successful.map((f: any) => ({
      name: f.name,
      token: result.uploadID,
      format: f.type,
      size: f.size,
      module: module,
      sub_module: subModule ? subModule : null,
      category: category ? category : null,
      sub_category: name ? name : null,
      module_id: moduleID,
      sub_id: subID ? subID : null,
      sub_db_name: subDB ? subDB : null,
      path: `${module}${subModule ? "/" + subModule : ""}/${f.name}`,
    }));
    updateFileList(fileData);
    if (mini && formContext) {
      const files = formContext?.getValues(name);
      if (files?.length) {
        formContext?.setValue(name, files + "," + result.uploadID);
      } else {
        formContext?.setValue(name, result.uploadID);
      }
    }
  }

  useEffect(() => {
    uppy.on("complete", handleUpload);
    return () => {
      uppy.off("complete", handleUpload);
    };
  }, [handleUpload]);

  return (
    <form className={"uppy-wrap" + (mini ? " mini-uppy" : "")}>
      <div>
        <DragDrop uppy={uppy} />
        <StatusBar uppy={uppy} hideAfterFinish={false} />
      </div>
      {mini && fileList ? <FileUploadListMini files={fileList} /> : <></>}
      <div>
        <div style={{ display: "flex" }}>
          {files.successful ? (
            <div className="field">
              {mini && name && formContext ? (
                <input
                  id={name}
                  // style={{fontSize:"8px", width:"100%"}}
                  type="hidden"
                  {...formContext?.register(name, {
                    value: "",
                  })}
                />
              ) : (
                <input
                  name="filenames"
                  hidden={true}
                  type="hidden"
                  value={fileNames.join(",")}
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
}
