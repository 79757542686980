import { useCallback, useContext, useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import VoucherGroupOptions from "./VoucherGroupOptions";
import { voucherStatus } from "./voucherStatus";
import { ViewContext } from "../contexts/ViewContext";
import VoucherDetails from "./VoucherDetails";
import editIcon from "../../images/icons/pencil.png";
import { inflect, objectOrJSONparse } from "../functions/tools";
import HybridField from "../forms_new/Elements/HybridField";
import Form from "../forms_new/Form";
import { hybridDocumentField } from "../calendar/addEventFormUpdated";
import VoucherEdit from "./VoucherEdit";

export default function VoucherList({
  items,
  heading,
  amount,
  paid,
  auth,
  setUpdate,
}: {
  items: any;
  heading: string;
  amount?: number;
  paid?: number;
  auth: number;
  setUpdate: Function;
}) {
  let itemsShow = items;
  if (amount !== undefined && paid !== undefined) {
    itemsShow = items?.filter(
      (i: any) => i?.amount === amount && i?.paid === paid
    );
  }
  itemsShow?.sort((a: any, b: any) => Number(a.order) - Number(b.order));

  const status = voucherStatus;

  const { setModal } = useContext(ViewContext);

  const [shift, setShift] = useState(false);
  const [lastClick, setLastClick] = useState<any>(0);
  const [index, setIndex] = useState<any>(0);
  const [bag, setBag] = useState<any>([]);
  useEffect(() => {
    setBag(itemsShow.map((i: any) => false));
  }, [itemsShow?.length]);

  const someChecked =
    bag?.reduce((a: boolean, b: boolean) => a || b, false) === true
      ? true
      : false;

  function checkRow(index: number) {
    if (shift && lastClick !== undefined) {
      setBag((prev: any) => {
        let n = prev?.slice();

        const start = lastClick < index ? lastClick : index;
        const end = lastClick > index ? lastClick : index;
        const section = n?.slice(start, end);
        console.log(start, end, n, section);
        let newValue =
          section?.reduce((a: boolean, b: boolean) => a && b, true) === true
            ? false
            : true;
        for (let x = start; x <= end; x++) {
          n[x] = newValue;
        }
        return n;
      });
    } else {
      setBag((prev: any) => {
        let n = prev?.slice();
        n[index] = !n[index];
        return n;
      });
    }

    setLastClick(index);
  }

  return (
    <div
      className="voucher-list"
      onKeyDown={(e: any) => {
        if (e.keyCode === 16) {
          setShift(true);
        }
      }}
      onKeyUp={(e: any) => {
        setShift(false);
      }}
    >
      {/* <h2>{heading}</h2> */}
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 3,
          background: "rgba(252,252,252)",
          display: "flex",
          padding:0,
          fontSize: "12px",
        }}
      >
        {someChecked ? (
          <div
            className="vouchers-selected"
            onClick={() =>
              setModal({
                show: true,
                style: { minWidth: "360px" },
                content: (
                  <VoucherEdit
                    data={itemsShow.filter(
                      (s: any, index: number) => bag?.[index]
                    )}
                    setUpdate={setUpdate}
                    setBag={setBag}
                    setModal={setModal}
                  />
                ),
                heading: "Edytuj vouchery",
              })
            }
          >
            Edytuj{" "}
            {inflect(bag?.filter((b: any) => b === true)?.length, [
              "voucherów",
              "voucher",
              "vouchery",
              "voucherów",
            ])}
          </div>
        ) : (
          <div className="vouchers-selected none">Nie zaznaczono voucherów</div>
        )}
        {/* {bag?.map(
          (b: any, index: number) =>
            b && (
              <div style={{ padding: "5px" }}>
                {itemsShow?.[index].number_text}
              </div>
            )
        )} */}
      </div>

      <table style={{ userSelect: "none" }}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                style={{ opacity: someChecked ? 1 : 0.1 }}
                checked={someChecked}
                onClick={() =>
                  setBag((prev: any) => prev?.map((g: any) => false))
                }
              />
            </th>
            {/* <th>Lp.</th> */}
            <th>Nr</th>
            <th>Data</th>
            <th>Zamówił(a)</th>
            <th>Klient</th>
            <th>Ważny do</th>
            <th>Status</th>
            <th>Akcje</th>
            <th>Grupa</th>
          </tr>
        </thead>
        <tbody>
          {itemsShow?.map((i: any, index: number, all: any[]) => {
            const sameGroup = all?.filter(
              (a: any) => a.group_id === i?.group_id
            );
            const rs = sameGroup?.length;
            let group_id = (
              <td rowSpan={rs} style={{ background: "white" }}>
                {auth > 1 && i?.status !== 0 ? (
                  <VoucherGroupOptions
                    items={sameGroup}
                    setUpdate={setUpdate}
                  />
                ) : (
                  <>GRUPA</>
                )}
              </td>
            );
            const next = all?.[index + 1];
            const prev = all?.[index - 1];

            if (prev && prev?.group_id === i?.group_id) {
              group_id = <></>;
            }
            return (
              <tr
                key={index}
                className={
                  "voucher-table-row" +
                  (i?.status === 0 ? " provisional" : "") +
                  (bag[index] ? " selected" : "")
                }
              >
                <td
                  style={{ width: "25px", cursor: "pointer" }}
                  onClick={() => i?.status !== 0 && checkRow(index)}
                >
                  {i?.status === 0 ? (
                    <></>
                  ) : (
                    <input type="checkbox" checked={bag[index]} />
                  )}
                </td>
                {/* <td style={{ width: "25px" }}>{index + 1}</td> */}
                <td
                  className={i?.status === 0 ? "" : "clickable-cell"}
                  style={{ width: "80px", fontSize: "120%" }}
                  onClick={() =>
                    i?.status !== 0 &&
                    setModal({
                      heading: "Voucher",
                      content: <VoucherDetails id={i?.id} />,
                      show: true,
                    })
                  }
                >
                  {i?.number_text
                    ? i?.number_text
                    : (i?.paid ? "" : "G") + i?.amount + "-" + i.number}
                </td>
                <td style={{ width: "80px" }}>
                  {i?.print_date
                    ? new Date(i?.print_date).toLocaleDateString()
                    : new Date().toLocaleDateString()}
                </td>
                <td style={{ width: "230px" }}>{i?.request_user}</td>
                <td style={{ width: "230px", fontSize: "11px" }}>
                  {i?.ordering_client}
                </td>
                <td style={{ width: "80px" }}>
                  {new Date(i?.valid_until)?.toLocaleDateString()}
                </td>
                <td>{i?.status !== undefined && status[i?.status]}</td>
                <td
                  onClick={() =>
                    i?.status !== 0 &&
                    setModal({
                      show: true,
                      style: { minWidth: "360px" },
                      content: (
                        <VoucherEdit
                          data={i}
                          setUpdate={setUpdate}
                          setBag={setBag}
                          setModal={setModal}
                        />
                      ),
                      heading: "Edytuj vouchery",
                    })
                  }
                >
                  {i?.status === 0 ? <></> : <img src={editIcon} width={18} />}
                </td>

                {group_id}
                {/* <td>{JSON.stringify(i)}</td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
