import { useFormContext } from "react-hook-form";
import { formField, option, optionsList } from "../formContentType";
import HybridField from "./HybridField";
import { useContext, useEffect, useState } from "react";
import Select from "./Select";
import MultiAddField from "./MultiAddField";
import { FormDataContext } from "../Form";
import MultiAdder from "./MultiAdder";
import Input from "./Input";
import SubTabs from "./SubTabs";
import SelectPicker from "./SelectPicker";
import FileUpload from "../../employeeFiles/FileUpload";
import FileUploadList from "../../employeeFiles/FileUploadList";
import useFiles from "./useFiles";

export default function Field({
  schema,
  visible,
}: {
  schema: formField;
  visible: boolean;
}) {
  const {
    element,
    label,
    optionsList,
    required,
    inputType,
    name,
    description,
    min,
    max,
    pattern,
    minLength,
    maxLength,
    rows,
    step,
    style,
    validate,
    icon,
    subItem,
    setSubItem,
    fileInfo,
    files,
    filesLoad
  } = schema;

  const {
    register,
    watch,
    formState: { errors, touchedFields },
    getValues,
    setValue,
  } = useFormContext();

  const loadFiles = useFiles(filesLoad, getValues(name));
  
  const hidden = schema.inputType === "hidden";

  const originalValue = watch(name.replace("-clone", ""));

  const [manualChanges, setManualChanges] = useState(0);
  const { setSubUpdate } = useContext(FormDataContext);

  // CLONED FIELDS HANDLE:
  useEffect(() => {
    if (name.match("-clone")) {
      setValue(name.replace("-clone", ""), watch(name), { shouldTouch: false });
    }
  }, [watch(name)]);

  useEffect(() => {
    if (name.match("-clone")) {
      setValue(name, originalValue, { shouldTouch: false });
    }
  }, [originalValue]);
  //END CLONED FIELDS HANDLE

  useEffect(() => {
    setSubUpdate((prev: number) => prev + 1);
  }, [manualChanges]);

  return description ? (
    <div
      className="field-description"
      style={{ ...style, display: hidden ? "none" : style?.display }}
    >
      {icon ? <img className="field-label-icon" src={icon} /> : <></>}
      {description}
    </div> /* EDYTOR UPRAWNIEŃ - ROBOCZY INTERFEJS : inputType !== "hidden" ? (
    <>
      {element === "multiAdd" ? <label>{label}</label> : <></>}
      <div className="auth-edit-field">
        <div>
          <select>
            <option value={0}>Edycja</option>
            <option value={0}>Podgląd</option>
            <option value={0}>Ukryte</option>
          </select>
        </div>
      </div>
    </
  ) */
  ) : element === undefined || element === "input" ? (
    <Input
      schema={schema}
      hidden={hidden}
      visible={visible}
      setManualChanges={setManualChanges}
    />
  ) : element === "select" ? (
    <Select
      schema={schema}
      hidden={hidden}
      // key={schema?.subDefaults?.["_" + name?.split("%")?.[1]]}
      setManualChanges={setManualChanges}
    />
  ) : element === "select-picker" ? (
    <SelectPicker
      schema={schema}
      hidden={hidden}
      // key={schema?.subDefaults?.["_" + name?.split("%")?.[1]]}
      setManualChanges={setManualChanges}
    />
  ) : element === "textarea" ? (
    <textarea
      className={touchedFields?.[name] ? " touched" : ""}
      rows={rows}
      placeholder={schema?.placeholder || ""}
      readOnly={schema?.readOnly ? true : false}
      id={name}
      {...register(name, {
        required: visible ? required : undefined,
        minLength: minLength,
        maxLength: maxLength,
        pattern: pattern,
        validate: validate,
        value: schema?.subDefaults?.["_" + name?.split("%")?.[0]],
      })}
      style={{ ...style, display: !visible ? "none" : "initial" }}
    />
  ) : element === "hybrid" ? (
    <HybridField schema={schema} />
  ) : element === "multiAdd" ? (
    <MultiAddField
      schema={schema}
      hidden={hidden}
      readOnly={schema?.readOnly ? true : false}
    />
  ) : element === "multiAddEditor" ? (
    <MultiAdder schema={schema} />
  ) : element === "subTabs" ? (
    <SubTabs schema={schema} />
  ) : element === "file" && visible ? (
    <>
      <FileUpload
        name={name}
        module={fileInfo?.module as string}
        subModule={fileInfo?.subModule as string}
        category={fileInfo?.category as string}
        moduleID={fileInfo?.moduleID as number}
        subID={
          (fileInfo?.subID ? fileInfo?.subID : getValues("guid")) as number
        }
        subDB={fileInfo?.subDB as string}
        mini={true}
        fileData={loadFiles || files}
        formContext={{
          register,
          watch,
          formState: { errors, touchedFields },
          getValues,
          setValue,
        }}
      />
    </>
  ) : (
    <></>
  );
}
