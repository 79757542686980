import { useContext, useEffect, useState } from "react";
import VoucherPDF from "./vouchers/VoucherPDF";
import VoucherGroup from "./vouchers/VoucherGroup";
import VoucherRegister from "./vouchers/VoucherRegister";
import VoucherList from "./vouchers/VoucherList";
import useGeneric from "./dataFetch/useGeneric";
import { UserContext } from "./contexts/UserContext";
import insertGeneric from "./dataFetch/insertGeneric";
import listIcon from "../images/icons/list.svg";
import addIcon from "../images/icons/add.svg";
import scanIcon from "../images/icons/scanner.svg";
import eyeIcon from "../images/icons/eye.svg";
import { objectOrJSONparse } from "./functions/tools";
import Info from "./common/Info";
import QRRead from "./vouchers/QRRead";

export default function Vouchers() {
  //   const [items, setItems] = useState([
  //     { amount: 100, number: 477 },
  //     { amount: 100, number: 478 },
  //   ]);

  const { userID, auth } = useContext(UserContext);

  const [showPDF, setShowPDF] = useState(false);

  const [update, setUpdate] = useState(0);

  const { loading: loadingMemory, rows: memoryData } = useGeneric({
    name: "voucher-register-memory",
    query: {
      print_user_id: userID,
    },
    // update: update,
    order: {
      by: ["print_order", "number"],
      dir: ["ASC", "DESC"],
    },
  });

  const memory = !loadingMemory && memoryData;
  //   console.log(memory);

  const [tab, setTab] = useState(0);
  const [finalize, setFinalize] = useState(false);
  const [groups, setGroups] = useState<any>([]);

  const { loading: loadingRegister, rows: registerData } = useGeneric({
    name: "voucher-register",
    update: JSON.stringify(groups) + update,
    secondsToReload: 45,
  });
  const voucherRegister =
    !loadingRegister && registerData?.filter((r: any) => r?.status != 99);

  const items: any = [];

  useEffect(() => {
    if (!loadingMemory) {
      const retrievedGroups: any = {};
      let copies: any = {};
      memoryData.forEach((i: any) => {
        if (i?.group_id) {
          copies[i?.group_id] = (copies[i?.group_id] || 0) + 1;
          retrievedGroups[i.group_id] = {
            ...i,
            first: i?.number,
            copies: copies?.[i?.group_id],
          };
        }
      });

      setGroups((prev: any) =>
        Object.keys(retrievedGroups)?.map((k: any) => retrievedGroups[k])
      );
      console.log(retrievedGroups);
    }
  }, [loadingMemory]);

  groups?.forEach((g: any) => {
    if (g) {
      const {
        first,
        copies,
        amount,
        paid,
        valid_until,
        type,
        for_name,
        request_user,
        request_user_id,
        ordering_client,
        group_id,
        comment,
        status,
      } = g;
      for (let i = 0; i < copies; i++) {
        items.push({
          number: first + i,
          amount: amount,
          paid: paid,
          valid_until: valid_until,
          type: type,
          for_name: for_name,
          request_user: request_user,
          request_user_id: request_user_id,
          ordering_client: ordering_client,
          group_id: group_id,
          comment: comment,
          status: status,
        });
      }
    }
  });
  useEffect(() => {
    // console.log(groups);
    const runDB = setTimeout(() => {
      //   console.log(items);
      let order = 0;
      insertGeneric(
        "set-voucher-memory",
        items && items.length
          ? items?.map((i: any, index: number, arr: any) => {
              if (
                arr.at(index - 1) &&
                arr.at(index - 1)?.group_id !== i?.group_id
              ) {
                order = order + 1;
              }
              return {
                ...i,
                group_id: i?.group_id,
                print_user_id: userID,
                print_date:
                  new Date().toISOString().slice(0, 10) +
                  " " +
                  new Date().toTimeString(),

                order: order,
              };
            })
          : { print_user_id: userID }
      );
    }, 800);
    return () => clearTimeout(runDB);
  }, [JSON.stringify(groups), JSON.stringify(items)]);

  const defaultValidDate = new Date();
  defaultValidDate.setDate(defaultValidDate.getDate() + 30 * 6);
  defaultValidDate.setDate(defaultValidDate.getDate() + 30);
  defaultValidDate.setDate(0);
  const voucherAuth = objectOrJSONparse(auth?.modules)?.vouchers;
  //   console.log(voucherAuth);
  return !voucherAuth ? (
    <Info type="unauthorised" />
  ) : (
    <div>
      <div className="voucher-nav-wrap">
        <nav className="voucher-nav">
          <div className={tab === 0 ? "active" : ""} onClick={() => setTab(0)}>
            <img src={listIcon} /> <div>Rejestr voucherów</div>
          </div>

          {voucherAuth === 9 ? (
            <div
              className={tab === 1 ? "active" : ""}
              onClick={() => setTab(1)}
            >
              <img src={addIcon} /> <div>Dodawanie</div>
            </div>
          ) : (
            <></>
          )}

          <div className={tab === 2 ? "active" : ""} onClick={() => setTab(2)}>
            <img src={scanIcon} /> <div>QR</div>
          </div>
        </nav>
        <nav className="voucher-nav">
          {tab === 1 ? (
            <div
              style={{ justifySelf: "right" }}
              onClick={() => setShowPDF((prev: boolean) => !prev)}
            >
              <img src={eyeIcon} /> <div></div>
            </div>
          ) : (
            <></>
          )}
        </nav>
      </div>
      {tab === 0 && voucherAuth ? (
        <VoucherRegister
          items={items}
          auth={voucherAuth}
          setUpdate={setUpdate}
          register={voucherRegister || []}
        />
      ) : finalize ? (
        <div className="voucher-finalize voucher-register">
          <button className="normal-button" onClick={() => setFinalize(false)}>
            Powrót
          </button>
          <div className="voucher-lists">
            <VoucherList
              setUpdate={setUpdate}
              auth={auth}
              items={items}
              heading={"Przegląd"}
            />
          </div>
          <div>
            <button
              onClick={() => {
                insertGeneric("set-voucher-register", {
                  print_user_id: userID,
                });
                //clear working memory
                insertGeneric("set-voucher-memory", { print_user_id: userID });
                setGroups([]);
              }}
              className="normal-button"
              style={{ marginTop: "12px" }}
            >
              Dodaj do rejestru jako wydrukowane
            </button>
          </div>

          <div
            style={{
              fontSize: "10px",
              fontFamily: "monospace",
              maxWidth: "600px",
            }}
          >
            {JSON.stringify(items)}
          </div>
        </div>
      ) : tab === 1 && voucherAuth === 9 ? (
        <div className="voucher-layout">
          <div className="voucher-editor">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <button
                className="normal-button"
                style={{
                  margin: "2px 8px",
                  fontWeight: "bold",
                  //   width: "200px",
                }}
                onClick={() => {
                  setGroups((prev: any[]) => [
                    ...prev,
                    {
                      first: 1,
                      copies: 1,
                      amount: 100,
                      paid: 1,
                      valid_until: defaultValidDate.toISOString().slice(0, 10),
                      type: 0,
                      for_name: "",
                      ordered_by: 0,
                      ordering_client: "",
                      group_id: "",
                      comment: "",
                      status: 0,
                    },
                  ]);
                }}
              >
                + Dodaj grupę
              </button>
              <div>
                Suma: <strong>{items?.length}</strong> | Strony:{" "}
                <strong>{Math.ceil(items?.length / 3)}</strong>
              </div>
              <div>
                <button
                  className={
                    "normal-button" + (items?.length ? "" : " disabled")
                  }
                  style={{
                    background: items?.length ? "lightgreen" : "whitesmoke",
                    fontWeight: "bold",
                    borderRadius: "6px",
                    padding: "12px 16px",
                    opacity: items?.length ? 1 : 0.13,
                    //   width: "200px",
                  }}
                  onClick={() => {
                    if (items?.length) {
                      setFinalize(true);
                    }
                  }}
                >
                  WPISZ DO REJESTRU <span style={{ fontSize: "18px" }}>↝</span>
                </button>
              </div>
            </div>

            <div className="voucher-groups-editor">
              {!groups?.length ? (
                <div
                  style={{
                    padding: "12px",
                    color: "grey",
                    fontSize: "12px",
                    marginTop: "12px",
                  }}
                >
                  Nie dodałeś jeszcze żadnej grupy voucherów.
                </div>
              ) : (
                groups?.map((g: any, index: number) =>
                  g ? (
                    <VoucherGroup
                      items={items}
                      group={g}
                      setGroups={setGroups}
                      groups={groups}
                      index={index}
                    />
                  ) : (
                    <></>
                  )
                )
              )}
            </div>
          </div>
          <div>
            {showPDF ? (
              <VoucherPDF items={items} />
            ) : (
              <div
                style={{
                  padding: "24px",
                }}
              >
                <button
                  className="normal-button huge"
                  onClick={() => {
                    setShowPDF(true);
                  }}
                >
                  Generuj PDF
                </button>
              </div>
            )}
          </div>
        </div>
      ) : tab === 2 ? (
        <QRRead />
      ) : (
        <></>
      )}{" "}
    </div>
  );
}
