import useGeneric from "../../dataFetch/useGeneric";

export default function useFiles(
  data: {
    module: "errors" | string;
    module_id: number;
    sub_module?: string;
    sub_category?: string;
    category?: string;
  },
  updates?: number
) {
  const files = useGeneric({
    name: data?.module && "getFiles",
    query: {
      ...data,
    },
    update: updates,
  });

  return !files?.loading
    ? files?.rows?.map((r: any) => ({
        ...r,
        field: r?.sub_category,
      }))
    : undefined;
}
